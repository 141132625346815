"use client";

import React from "react";
import { MessageOutlined } from "@ant-design/icons";
import { assets } from "@koble/assets/assets";
import { Avatar, Tooltip, Typography } from "antd";

import PublicSwitchPrimaryButton from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchPrimaryButton";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import es from "@/lang/es";

const ContactComponent = () => {
  const { pageType } = usePublicSwitchPage();
  const ContactCard = (
    <div
      style={{
        width: "100%",
        padding: 8,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          width: 60,
        }}
      >
        <Avatar
          style={{
            height: 50,
            width: 50,
            marginRight: 10,
          }}
          src={assets.KOBOT_HAPPY_LARGE}
        />
      </div>
      <div>
        <Typography.Text
          style={{
            fontWeight: 600,
            fontSize: 16,
            display: "block",
          }}
        >
          {es.switch.contact.title}
        </Typography.Text>
        <Typography.Text
          style={{
            fontWeight: 400,
            fontSize: 13,
            display: "block",
            color: "#7a7a7a",
          }}
        >
          {es.switch.contact.subTitle[0]}
          <br />
          {es.switch.contact.subTitle[1]}
        </Typography.Text>
      </div>
    </div>
  );

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: 24,
        zIndex: 9999999999,
      }}
    >
      <Tooltip title={ContactCard} placement="topRight" color="#FFFFFF">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <a
            href={es.switch.footer.contact.phoneLink}
            target="_blank"
            rel="noreferrer"
          >
            <PublicSwitchPrimaryButton
              label={es.switch.contact.button}
              icon={<MessageOutlined />}
              size="small"
              color="white"
              background={
                pageType === "userStudent"
                  ? generatePublicLinearGradient({
                      colorType: "blue",
                      angle: 90,
                    })
                  : generatePublicLinearGradient({
                      colorType: "orange",
                      angle: 90,
                    })
              }
              style={{
                border: "none",
              }}
            />
          </a>
        </div>
      </Tooltip>
    </div>
  );
};

export default ContactComponent;
