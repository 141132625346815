"use client";

import { ReactNode } from "react";
import { animated, useSpring } from "@react-spring/web";

import {
  PublicSwitchHeaderContent,
  PublicSwitchHeaderFooter,
  PublicSwitchHeaderNavBar,
} from "@/app/public-switch/common/PublicSwitchHeader";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import { PUBLIC_GRAY_GRADIENT_FROM_COLOR } from "@/common/publicColors";

const PublicSwitchClientLayout = ({ children }: { children: ReactNode }) => {
  const { pageType } = usePublicSwitchPage();

  const { backgroundColor } = useSpring({
    backgroundColor:
      pageType === "userStudent" ? "#FFFFFF" : PUBLIC_GRAY_GRADIENT_FROM_COLOR,
  });

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: 0,
          // Yeah baby! Z-index 9999999999!
          zIndex: 99,
        }}
      >
        <PublicSwitchHeaderNavBar />
      </div>
      <PublicSwitchHeaderContent />
      <PublicSwitchHeaderFooter />
      <animated.div
        style={{
          minHeight: "100vh",
          backgroundColor,
        }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default PublicSwitchClientLayout;
