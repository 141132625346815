import config from "./config";

const getBaseRoute = (isAbsolute?: boolean) => {
  return isAbsolute ? config.NEXT_PUBLIC_USER_STUDENT_FRONTEND : "";
};

const userStudentRouteManager = {
  home(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/`;
  },

  business(businessId?: string, isAbsolute?: boolean) {
    if (!businessId) return "";

    return `${getBaseRoute(isAbsolute)}/empresa/${businessId}`;
  },

  chat(chatId?: string, isAbsolute?: boolean, params?: string) {
    return `${getBaseRoute(isAbsolute)}/chat${chatId ? `/${chatId}` : ""}${
      params ? `/${params}` : ""
    }`;
  },

  job(jobId?: string, isAbsolute?: boolean) {
    if (!jobId) return "";

    return `${getBaseRoute(isAbsolute)}/job/${jobId}`;
  },

  jobs(isAbsolute?: boolean, params?: string, searchParams?: string) {
    return `${getBaseRoute(isAbsolute)}/empleos${params ? `/${params}` : ""}${
      searchParams ? `?${searchParams}` : ""
    }`;
  },

  legal(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/legal`;
  },

  likesReceived(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/likes-received`;
  },

  matches(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/matches`;
  },

  notifications(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/notifications`;
  },

  onboarding(isAbsolute?: boolean, searchParams?: string) {
    return `${getBaseRoute(isAbsolute)}/onboarding${
      searchParams ? `?${searchParams}` : ""
    }`;
  },

  onboardingNames(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/names`;
  },

  onboardingArea(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/area`;
  },

  onboardingDateOfBirth(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/dateOfBirth`;
  },

  onboardingLocation(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/location`;
  },

  onboardingWorkScheduleType(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/workScheduleType`;
  },

  onboardingExpectations(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/expectations`;
  },

  onboardingKeyCompetencies(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/keyCompetencies`;
  },

  onboardingSkills(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/skills`;
  },

  onboardingUniversity(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/university`;
  },

  onboardingCareer(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/career`;
  },

  onboardingAbout(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/onboarding/about`;
  },

  profile(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/profile`;
  },

  profileCompletion(isAbsolute?: boolean, searchParams?: string) {
    return `${getBaseRoute(isAbsolute)}/profile-completion${
      searchParams ? `?${searchParams}` : ""
    }`;
  },

  profileCompletionProfileImage(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/profile-completion/profileImage`;
  },

  profileCompletionEducation(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/profile-completion/education`;
  },

  profileCompletionAcademicProject(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/profile-completion/academicProject`;
  },

  recruiter(userRecruiterId?: string, isAbsolute?: boolean) {
    if (!userRecruiterId) return "";

    return `${getBaseRoute(isAbsolute)}/recruiter/${userRecruiterId}`;
  },

  settings(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/settings`;
  },

  settingsSecurity(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/settings/security`;
  },

  thirdPartyJob(thirdPartyJobId: string, isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/empleo-externo/${thirdPartyJobId}`;
  },
};

export default userStudentRouteManager;
