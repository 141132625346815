"use client";

import { useEffect, useState } from "react";
import { assets } from "@koble/assets/assets";
import publicRouteManager from "@koble/config/src/publicRouteManager";
import ssoRouteManager from "@koble/config/src/ssoRouteManager";
import userRecruiterRouteManager from "@koble/config/src/userRecruiterRouteManager";
import userStudentRouteManager from "@koble/config/src/userStudentRouteManager";
import { useAuth } from "@koble/hooks";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { animated, useSpring } from "@react-spring/web";
import { Space } from "antd";
import Link from "next/link";

import { PublicSwitchHeaderNavBarMobile } from "@/app/public-switch/common/PublicSwitchHeader/index";
import PublicSwitchPrimaryButton from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchPrimaryButton";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import AnimateOnScroll from "@/common/AnimateOnScroll";
import {
  PUBLIC_BLUE_GRADIENT_FROM_COLOR,
  PUBLIC_ORANGE_GRADIENT_FROM_COLOR,
} from "@/common/publicColors";
import es from "@/lang/es";

const GhostButton = ({ active, label }: { active: boolean; label: string }) => (
  <PublicSwitchPrimaryButton
    label={label}
    color="white"
    background="transparent"
    style={{
      opacity: active ? 1 : 0.6,
      fontWeight: active ? 600 : 400,
      border: "none",
      paddingLeft: 5,
      paddingRight: 5,
    }}
  />
);

const PublicSwitchHeaderNavBar = () => {
  const { identityClaims } = useAuth();
  const { pageType } = usePublicSwitchPage();
  const { isMobile } = useIsMobile();

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let goToAppLink;
  let signInLink;
  let signUpLink;

  switch (pageType) {
    case "userStudent":
      goToAppLink = userStudentRouteManager.home(true);
      signInLink = ssoRouteManager.home(true);
      signUpLink = ssoRouteManager.signUpUserStudent(true);
      break;
    case "userRecruiter":
      goToAppLink = userRecruiterRouteManager.home(true);
      signInLink = ssoRouteManager.home(true);
      signUpLink = ssoRouteManager.signUpUserRecruiter(true);
      break;
    default:
      goToAppLink = ssoRouteManager.home(true);
      signInLink = ssoRouteManager.home(true);
      signUpLink = ssoRouteManager.home(true);
  }

  const onTop = scrollPosition === 0;

  let bgColor;

  if (pageType === "userRecruiter") {
    if (onTop) {
      bgColor = PUBLIC_ORANGE_GRADIENT_FROM_COLOR;
    } else {
      let fade = 1 - scrollPosition / 5000;
      if (fade < 0.9) fade = 0.9;
      bgColor = `rgba(235,122,21,${fade})`;
    }
  }

  if (pageType === "userStudent") {
    if (onTop) {
      bgColor = PUBLIC_BLUE_GRADIENT_FROM_COLOR;
    } else {
      let fade = 1 - scrollPosition / 5000;
      if (fade < 0.9) fade = 0.9;
      bgColor = `rgba(21,159,196,${fade})`;

      bgColor = `rgba(21,159,196, ${fade})`;
    }
  }

  const props = useSpring({
    backgroundColor: bgColor,
  });

  return (
    <animated.div
      style={{
        height: 70,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px",
        ...props,
      }}
    >
      <AnimateOnScroll
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1200,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isMobile && (
            <>
              <Link
                href={publicRouteManager.home()}
                title={es.switch.header.navbar.logoTitle}
              >
                <img
                  src={assets.KOBLE_LOGO_WHITE_WITH_TEXT}
                  alt={es.switch.header.navbar.logoAlt}
                />
              </Link>
              <div style={{ flexGrow: 1 }} />

              <Space size={16}>
                <Link
                  href={es.switch.metadata.userStudentSlug}
                  title={es.switch.header.navbar.userStudentTitle}
                >
                  <GhostButton
                    active={pageType === "userStudent"}
                    label={es.switch.header.navbar.userStudent}
                  />
                </Link>
                <Link
                  href={es.switch.metadata.userRecruiterSlug}
                  title={es.switch.header.navbar.userRecruiterTitle}
                >
                  <GhostButton
                    active={pageType === "userRecruiter"}
                    label={es.switch.header.navbar.userRecruiter}
                  />
                </Link>
                {identityClaims ? (
                  <a
                    href={goToAppLink}
                    target="_blank"
                    rel="noreferrer"
                    title={es.switch.header.navbar.goToAppTitle}
                    style={{ marginLeft: 8 }}
                  >
                    <PublicSwitchPrimaryButton
                      background="white"
                      label={es.switch.header.navbar.goToApp}
                      color={
                        pageType === "userStudent"
                          ? PUBLIC_BLUE_GRADIENT_FROM_COLOR
                          : PUBLIC_ORANGE_GRADIENT_FROM_COLOR
                      }
                    />
                  </a>
                ) : (
                  <Space size={16}>
                    <a
                      href={signInLink}
                      title={es.switch.header.navbar.signInTitle}
                    >
                      <PublicSwitchPrimaryButton
                        label={es.switch.header.navbar.signIn}
                        color="white"
                        background="transparent"
                      />
                    </a>
                    <a
                      href={signUpLink}
                      title={es.switch.header.navbar.signUpTitle}
                    >
                      <PublicSwitchPrimaryButton
                        label={es.switch.header.navbar.signUp}
                        color={
                          pageType === "userStudent"
                            ? PUBLIC_BLUE_GRADIENT_FROM_COLOR
                            : PUBLIC_ORANGE_GRADIENT_FROM_COLOR
                        }
                        background="white"
                      />
                    </a>
                  </Space>
                )}
              </Space>
            </>
          )}

          {isMobile && <PublicSwitchHeaderNavBarMobile key="mobile" />}
        </div>
      </AnimateOnScroll>
    </animated.div>
  );
};

export default PublicSwitchHeaderNavBar;
