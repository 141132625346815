import { Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import es from "@/lang/es";

const PublicSwitchHeaderTitle = ({
  fontSize,
  lineHeight,
}: {
  fontSize: number;
  lineHeight: number;
}) => {
  const { pageType } = usePublicSwitchPage();

  return (
    <Typography.Title
      level={1}
      style={{
        color: "white",
        fontSize,
        fontWeight: 600,
        maxWidth: 820,
        textAlign: "center",
        lineHeight: `${lineHeight}px`,
        margin: 0,
      }}
    >
      {pageType === "userStudent"
        ? es.switch.header.content.title.userStudent
        : es.switch.header.content.title.userRecruiter}
    </Typography.Title>
  );
};

export default PublicSwitchHeaderTitle;
