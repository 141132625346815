import { ConfigProvider, Space, Switch, Typography } from "antd";
import { useRouter } from "next/navigation";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import es from "@/lang/es";

const PublicSwitchHeaderSwitch = () => {
  const router = useRouter();
  const { pageType } = usePublicSwitchPage();

  return (
    <Space size={10}>
      <Typography.Text
        style={{
          color: "white",
          fontWeight: 600,
          opacity: pageType === "userStudent" ? 1 : 0.6,
          fontSize: 16,
        }}
      >
        {es.switch.header.content.switch.userStudent}
      </Typography.Text>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ffffff",
            colorTextQuaternary: generatePublicLinearGradient({
              colorType: "orange",
              angle: 180,
            }),
          },
          components: {
            Switch: {
              trackMinWidth: 50,
              trackHeight: 26,
              handleSize: 18,
              trackPadding: 4,
              handleBg: pageType === "userRecruiter" ? "#18BFFF" : "#FFFFFF",
            },
          },
        }}
      >
        <Switch
          title={es.switch.header.content.switch.title}
          checked={pageType === "userRecruiter"}
          onChange={(val) => {
            router.push(
              val
                ? es.switch.metadata.userRecruiterSlug
                : es.switch.metadata.userStudentSlug
            );
          }}
        />
      </ConfigProvider>

      <Typography.Text
        style={{
          color: "white",
          fontSize: 16,
          fontWeight: 600,
          opacity: pageType === "userRecruiter" ? 1 : 0.6,
        }}
      >
        {es.switch.header.content.switch.userRecruiter}
      </Typography.Text>
    </Space>
  );
};

export default PublicSwitchHeaderSwitch;
