import { Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import es from "@/lang/es";

const PublicSwitchHeaderSubTitle = ({
  fontSize,
  lineHeight,
}: {
  fontSize: number;
  lineHeight: number;
}) => {
  const { pageType } = usePublicSwitchPage();
  return (
    <Typography.Title
      level={2}
      style={{
        color: "white",
        fontSize,
        fontWeight: 400,
        maxWidth: 850,
        textAlign: "center",
        lineHeight: `${lineHeight}px`,
        margin: 0,
      }}
    >
      {pageType === "userStudent"
        ? es.switch.header.content.subTitle.userStudent
        : es.switch.header.content.subTitle.userRecruiter}
    </Typography.Title>
  );
};

export default PublicSwitchHeaderSubTitle;
