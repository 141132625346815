import config from "./config";

const getBaseRoute = (isAbsolute?: boolean) => {
  return isAbsolute ? config.NEXT_PUBLIC_USER_RECRUITER_FRONTEND : "";
};

const userRecruiterRouteManager = {
  home(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/`;
  },

  billing(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/billing`;
  },

  business(businessId?: string, isAbsolute?: boolean) {
    if (!businessId) return "";

    return `${getBaseRoute(isAbsolute)}/empresa/${businessId}`;
  },

  candidate(userStudentId?: string, isAbsolute?: boolean) {
    if (!userStudentId) return "";

    return `${getBaseRoute(isAbsolute)}/candidate/${userStudentId}`;
  },

  chat(chatId?: string, isAbsolute?: boolean, params?: string) {
    return `${getBaseRoute(isAbsolute)}/chat${chatId ? `/${chatId}` : ""}${
      params ? `/${params}` : ""
    }`;
  },

  explore(isAbsolute?: boolean, params?: string) {
    return `${getBaseRoute(isAbsolute)}/explore${params ? `/${params}` : ""}`;
  },

  job(jobId?: string, isAbsolute?: boolean) {
    if (!jobId) return "";

    return `${getBaseRoute(isAbsolute)}/job/${jobId}`;
  },

  likesReceived(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/likes-received`;
  },

  matches(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/matches`;
  },

  myJobs(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/my-jobs`;
  },

  notifications(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/notifications`;
  },

  profile(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/profile`;
  },

  recruiter(userRecruiterId?: string, isAbsolute?: boolean) {
    if (!userRecruiterId) return "";

    return `${getBaseRoute(isAbsolute)}/recruiter/${userRecruiterId}`;
  },

  settings(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/settings`;
  },

  settingsSecurity(isAbsolute?: boolean) {
    return `${getBaseRoute(isAbsolute)}/settings/security`;
  },
};

export default userRecruiterRouteManager;
