import { animated, useSpring } from "@react-spring/web";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import {
  PUBLIC_BLUE_GRADIENT_TO_COLOR,
  PUBLIC_GRAY_GRADIENT_FROM_COLOR,
  PUBLIC_ORANGE_GRADIENT_TO_COLOR,
} from "@/common/publicColors";

const PublicSwitchHeaderFooter = () => {
  const { pageType } = usePublicSwitchPage();

  const { backgroundColor, fill } = useSpring({
    fill:
      pageType === "userStudent"
        ? PUBLIC_BLUE_GRADIENT_TO_COLOR
        : PUBLIC_ORANGE_GRADIENT_TO_COLOR,
    backgroundColor:
      pageType === "userStudent" ? "#FFFFFF" : PUBLIC_GRAY_GRADIENT_FROM_COLOR,
  });

  return (
    <animated.div
      style={{
        backgroundColor,
      }}
    >
      <animated.svg
        width="100%"
        height="39"
        viewBox="0 0 500 21"
        preserveAspectRatio="none"
        style={{
          backgroundColor,
        }}
      >
        <animated.path d="M0,0 L0,0 Q250,39 500,0 L500,0 Z" fill={fill} />
      </animated.svg>
    </animated.div>
  );
};

export default PublicSwitchHeaderFooter;
